import { Link } from "gatsby"
import React, { FC } from "react"
import { breadcrumbtype, useGetBreadcrumbs } from "../../hooks/getBreadcrumbs"
import style from "./breadcrumbs.module.scss"

const Breadcrumbs = () => {
  // Get breadcrumbs using hook
  const breadcrumbs = useGetBreadcrumbs()
  //   guard clause for breadcrumbs
  if (!breadcrumbs) return null
  return (
    <div className={style.breadcrumbs}>
      <Link to="/">OCD</Link>
      {/* Map through breadcrumbs */}
      {breadcrumbs.map(breadcrumb => (
        <Breadcrumb key={`breadcrumbLink_${breadcrumb.slug}`} {...breadcrumb} />
      ))}
    </div>
  )
}

export default Breadcrumbs

const divider = ` > `

const Breadcrumb: FC<breadcrumbtype> = ({ label, slug }) => (
  <>
    {divider}
    <Link to={`${slug}/`}>{label}</Link>
  </>
)
