import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { FC } from "react"
import { productType } from "../../../types/fragrance"
import Breadcrumbs from "../../components/utilities/breadcrumbs/breadcrumbs"
import RichText from "../../components/utilities/rich-text/richText"
import SEO from "../../components/utilities/seo/SEO"
import Layout from "../../layout/layout"
import style from "./productType.module.scss"
// @ts-ignore
import { getFluidGatsbyImage } from "gatsby-storyblok-image"

export const data = graphql`
  query($uuid: String) {
    storyblokEntry(uuid: { eq: $uuid }) {
      content
      name
      slug
    }
  }
`

const ProductTypeTemplateWrapper = ({ data: query }: any) => {
  const {
    storyblokEntry: { content, name, slug },
  } = query

  const jsonContent: productType = JSON.parse(content)

  return <ProductTypeTemplate {...jsonContent} name={name} slug={slug} />
}

const ProductTypeTemplate: FC<productType> = ({
  body,
  featured_image,
  page_title,
  short_description,
}) => {
  const featuredImage = getFluidGatsbyImage(featured_image.filename, {
    maxHeight: 300,
  })
  return (
    <Layout title={page_title} darkHeader className={style.productTypeTemplate}>
      <SEO
        lang="en"
        title={`${page_title} professional odour eliminator`}
        description={short_description}
        image={featured_image?.filename || undefined}
        keywords={`Odour neutraliser, Odour eliminators, Odour neutraliser products, ${page_title}`}
        meta
        schemaMarkup={{
          "@context": "https://schema.org/",
          "@graph": [
            {
              "@type": "Product",
              name: `${page_title}`,
              image: `${featured_image?.filename}`,
              description: `${short_description}`,
              brand: {
                "@type": "Brand",
                name: "OCD - Odour Control Developments",
              },
            },
            {
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "OCD",
                  item: "https://ocdonline.co.uk/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Products",
                  item: "https://ocdonline.co.uk/odour-neutraliser-products/",
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: `${page_title}`,
                },
              ],
            },
          ],
        }}
      />
      <div className={style.content}>
        <Breadcrumbs />
        <div className={style.imageWrapper}>
          {featured_image && featured_image.filename && (
            <GatsbyImage
              fluid={featuredImage}
              alt={featured_image?.alt}
              className={style.image}
            />
          )}
        </div>
        <RichText
          className={style.richText}
          body={body}
          page_title={page_title}
          featured_image={featured_image}
        />
      </div>
    </Layout>
  )
}

export default ProductTypeTemplateWrapper
