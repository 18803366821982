import { useEffect, useState } from "react"

const capitaliseEachWord = (str: string) => {
  const splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}

export interface breadcrumbtype {
  slug: string
  label: string
}

// Get the breadcrumbs for using the current slug
export const useGetBreadcrumbs = () => {
  // Hold the breadcrumbs in hook state
  const [breadcrumbs, setBreadcrumbs] = useState<breadcrumbtype[] | null>(null)
  //   Logic for if is client side
  const clientSide = typeof location !== "undefined"
  //   Get breadcrumbs on first render
  useEffect(() => {
    // Guard clause for not client side
    if (!clientSide) return
    // Get the current pathname
    const { pathname } = location
    // Split url to an array
    const slugs = pathname
      .split("/")
      .filter(node => node !== "/" && node !== "")

    // Get a plain text version of the slug
    const pagesWithData = slugs.map((slug, index) => {
      const deslugged = capitaliseEachWord(slug.replace(/-/g, " "))
      const fullSlug = "/" + slugs.slice(0, index + 1).join("/")
      return { slug: fullSlug, label: deslugged }
    })
    // Set breadcrumbs in state
    setBreadcrumbs(pagesWithData)
  }, [])
  // Return the breadcrumbs
  return breadcrumbs
}
